import {
    cilAccountLogout,
    cilArrowBottom,
    cilArrowTop,
    cilBrowser,
    cilCloudDownload,
    cilCloudUpload,
    cilColorPalette,
    cilDataTransferDown,
    cilDescription,
    cilExternalLink,
    cilFactorySlash,
    cilFile,
    cilFindInPage,
    cilFolder,
    cilGlobeAlt,
    cilGraph,
    cilGroup,
    cilIndustry,
    cilInstitution,
    cilLan,
    cilLink,
    cilLinkAlt,
    cilLockLocked,
    cilLockUnlocked,
    cilMap,
    cilMenu,
    cilPen,
    cilReload,
    cilSave,
    cilSettings,
    cilSpreadsheet,
    cilTrash,
    cilUser,
    cilUserPlus,
    cilViewColumn,
} from '@coreui/icons';

export const iconSubset = {
    cilMenu,
    cilUser,
    cilLockLocked,
    cilTrash,
    cilCloudUpload,
    cilCloudDownload,
    cilDataTransferDown,
    cilSave,
    cilLink,
    cilLinkAlt,
    cilPen,
    cilSpreadsheet,
    cilFile,
    cilViewColumn,
    cilBrowser,
    cilGraph,
    cilSettings,
    cilMap,
    cilLan,
    cilUserPlus,
    cilIndustry,
    cilGroup,
    cilLockUnlocked,
    cilReload,
    cilGlobeAlt,
    cilInstitution,
    cilDescription,
    cilAccountLogout,
    cilFactorySlash,
    cilColorPalette,
    cilFindInPage,
    cilFolder,
    cilExternalLink,
    cilArrowTop,
    cilArrowBottom,
};

export enum IconSubset {
    cilMenu = 'cilMenu',
    cilUser = 'cilUser',
    cilLockLocked = 'cilLockLocked',
    cilTrash = 'cilTrash',
    cilCloudUpload = 'cilCloudUpload',
    cilCloudDownload = 'cilCloudDownload',
    cilDataTransferDown = 'cilDataTransferDown',
    cilSave = 'cilSave',
    cilLink = 'cilLink',
    cilLinkAlt = 'cilLinkAlt',
    cilPen = 'cilPen',
    cilSpreadsheet = 'cilSpreadsheet',
    cilFile = 'cilFile',
    cilViewColumn = 'cilViewColumn',
    cilBrowser = 'cilBrowser',
    cilGraph = 'cilGraph',
    cilSettings = 'cilSettings',
    cilMap = 'cilMap',
    cilLan = 'cilLan',
    cilUserPlus = 'cilUserPlus',
    cilIndustry = 'cilIndustry',
    cilGroup = 'cilGroup',
    cilLockUnlocked = 'cilLockUnlocked',
    cilReload = 'cilReload',
    cilGlobeAlt = 'cilGlobeAlt',
    cilInstitution = 'cilInstitution',
    cilDescription = 'cilDescription',
    cilAccountLogout = 'cilAccountLogout',
    cilFactorySlash = 'cilFactorySlash',
    cilColorPalette = 'cilColorPalette',
    cilFindInPage = 'cilFindInPage',
    cilFolder = 'cilFolder',
    cilExternalLink = 'cilExternalLink',
    cilArrowTop = 'cilArrowTop',
    cilArrowBottom = 'cilArrowBottom',
}
