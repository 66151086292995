import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { SessionService } from './session.service';
import { map, of, switchMap } from 'rxjs';

export const isGroupSelectedGuard = () => {
    const router = inject(Router);
    const sessionService = inject(SessionService);

    return sessionService.assumedRole$.pipe(
        switchMap(
            (role) =>
                ({
                    SYSTEM_ADMIN: sessionService.groupOrg$.pipe(map(({ group }) => group)),
                    ORG_ADMIN: sessionService.groupOrg$.pipe(map(({ organization }) => organization)),
                    ROOT_ADMIN: of(true),
                    USER: of(false),
                    '': of(false),
                })[role]
        ),
        map((entity) => (entity ? true : router.createUrlTree(['/login'])))
    );
};
