<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
    <c-container class="d-flex" [fluid]="true">
        <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
            <svg cIcon name="cilMenu" size="lg"></svg>
        </button>
        <c-header-nav class="ms-3">
            <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
        </c-header-nav>
    </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
    <c-dropdown alignment="end" variant="nav-item">
        <button cButton [caret]="false" cDropdownToggle class="py-0 text-transform-none" color="light">
            @if (user(); as user) {
                <c-avatar shape="rounded-1" size="sm" [src]="user.picture" status="success"></c-avatar>
                <span class="ms-1 d-none d-md-inline">{{ user.email }}</span>
                <span class="ms-2 badge bg-info">{{ roleLabel() }}</span>
                <span class="ms-2">{{ groupOrg()?.group?.name }}</span>
                @if (isOrgAdmin()) {
                    <span class="ms-2">{{ groupOrg()?.organization?.name }}</span>
                }
            }
        </button>
        <ul cDropdownMenu class="pt-0 pr-5 w-auto">
            <li>
                <button routerLink="'/login'" cDropdownItem class="mt-2">
                    <svg cIcon class="me-2" name="cilFactorySlash"></svg>
                    @if (isGroupAdmin()) {
                        Switch group
                    } @else if (isOrgAdmin()) {
                        Switch organization
                    } @else {
                        Switch role
                    }
                </button>
                @if (isOriginalRootAdmin()) {
                    <button (click)="loseRole()" cDropdownItem>Switch role</button>
                }
                <button (click)="logout()" cDropdownItem>
                    <svg cIcon class="me-2" name="cilAccountLogout"></svg>
                    Sign out
                </button>
            </li>
        </ul>
    </c-dropdown>
</ng-template>
