<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
    <c-container class="d-flex" [fluid]="true">
        <div class="d-flex">
            <div class="flex">
                <img class="logo" src="assets/fa-logo-main.png" alt="FarmAdvisor">
            </div>
            <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
                <svg cIcon name="cilMenu" size="lg"></svg>
            </button>
        </div>
        <c-header-nav class="ms-3">
            <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
        </c-header-nav>
    </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
    <c-dropdown alignment="end" variant="nav-item">
        <button cButton [caret]="false" cDropdownToggle class="py-2 text-transform-none" color="light">
            @if (user(); as user) {
            @if (isOriginalRootAdmin()) {
            <span class="ms-2">{{ groupOrg()?.group?.name }}</span> | <span class="ms-1">{{
                groupOrg()?.organization?.name }}</span>
            <span class="ms-2 badge bg-info">{{ roleLabel() }}</span>
            }@else if (isOrgAdmin()) {
            <span class="ms-1">{{
                groupOrg()?.organization?.name }}</span>
            }
            <c-avatar class="ms-3" shape="rounded-1" size="sm" [src]="user.picture" status="success"></c-avatar>
            <span class="ms-1 d-none d-md-inline">{{ user.email }}</span>
            }
        </button>
        <ul cDropdownMenu class="pt-0 pr-5 w-auto">
            <li>
                <button routerLink="'/login'" cDropdownItem class="mt-2">
                    <svg cIcon class="me-2" name="cilFactorySlash"></svg>
                    @if (isGroupAdmin()) {
                    Switch group
                    } @else if (isOrgAdmin()) {
                    Switch organization
                    } @else {
                    Switch role
                    }
                </button>
                @if (isOriginalRootAdmin()) {
                <button (click)="loseRole()" cDropdownItem>Switch role</button>
                }
                <button (click)="logout()" cDropdownItem>
                    <svg cIcon class="me-2" name="cilAccountLogout"></svg>
                    Sign out
                </button>
            </li>
        </ul>
    </c-dropdown>
</ng-template>