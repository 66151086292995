import { Component, computed, Input } from '@angular/core';

import { HeaderComponent } from '@coreui/angular';
import { AuthService } from '@auth0/auth0-angular';
import { SessionService } from '../../../services/session/session.service';
import { UsersApiService } from '../../../services/session/users-api.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Component({
    selector: 'app-default-header',
    templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {
    @Input() sidebarId = 'sidebar';
    user = toSignal(this.auth.user$);
    orginialRole = toSignal(this.sessionService.originalRole$);
    userRole = toSignal(this.sessionService.assumedRole$);
    roleLabel = computed(() => this.userApiService.roleToLabel(this.userRole() || ''));
    groupOrg = toSignal(this.sessionService.groupOrg$);
    isOrgAdmin = computed(() => this.userRole() === 'ORG_ADMIN');
    isGroupAdmin = computed(() => this.userRole() === 'SYSTEM_ADMIN');
    isOriginalRootAdmin = computed(() => this.orginialRole() === 'ROOT_ADMIN');

    constructor(
        private auth: AuthService,
        private sessionService: SessionService,
        private userApiService: UsersApiService,
        private router: Router
    ) {
        super();
    }

    logout() {
        this.sessionService.clearGroupOrg();
        this.auth.logout({ logoutParams: { returnTo: document.location.origin + '/login' } });
    }

    loseRole() {
        this.sessionService.clearGroupOrg();
        this.sessionService.roleOverride$.next('');
        this.router.navigate(['/login']);
    }
}
