import { ChangeDetectionStrategy, Component, computed, DestroyRef, signal } from '@angular/core';
import {
    AvatarComponent,
    ButtonDirective,
    CardBodyComponent,
    CardComponent,
    CardGroupComponent,
    ColComponent,
    ContainerComponent,
    DropdownItemDirective,
    RowComponent,
    SpinnerComponent,
    TextColorDirective,
} from '@coreui/angular';
import { AuthService } from '@auth0/auth0-angular';
import { AsyncPipe, NgClass, NgForOf, NgTemplateOutlet } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { LoadingComponent } from '../../partials/loading/loading.component';
import { SessionService } from '../../../services/session/session.service';
import { catchError, filter, of, switchMap, tap } from 'rxjs';
import { UsersApiService } from '../../../services/session/users-api.service';
import { ButtonModule } from 'primeng/button';
import { Organization, Role } from '../../../services/session/user.model';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Group } from '../../../services/api/group.model';
import { TooltipModule } from 'primeng/tooltip';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';

@Component({
    standalone: true,
    imports: [
        ContainerComponent,
        RowComponent,
        ColComponent,
        CardGroupComponent,
        CardComponent,
        CardBodyComponent,
        ButtonDirective,
        AsyncPipe,
        AvatarComponent,
        TextColorDirective,
        RouterLink,
        LoadingComponent,
        DropdownItemDirective,
        NgForOf,
        ButtonModule,
        NgClass,
        SpinnerComponent,
        TooltipModule,
        NgTemplateOutlet,
    ],
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    notAccessToGroup = signal(JSON.parse(this.localStorageService.get('notAccessToGroup', 'false')))
    authUser = toSignal(this.auth.user$);
    originalRole = toSignal(this.session.originalRole$.pipe(tap(d => {
        if (!d) this.notAccessToGroup.set(JSON.parse(this.localStorageService.get('notAccessToGroup', 'false')))
        else this.localStorageService.remove('notAccessToGroup')
    })));
    assumedRole = toSignal(this.session.assumedRole$);
    roleLabel = computed(() => this.userApi.roleToLabel(this.assumedRole() || ''));
    organizationOptionsError = signal(false);
    organizationOptions = toSignal(
        toObservable(this.originalRole).pipe(
            filter((role) => !!role),
            switchMap(() =>
                this.userApi.getOrganizations$().pipe(
                    catchError(() => {
                        this.organizationOptionsError.set(true);
                        return of(<Organization[]>[]);
                    })
                )
            )
        )
    );
    groupOptionsError = signal(false);
    groupOptions = toSignal(
        toObservable(this.originalRole).pipe(
            filter((role) => role === 'ROOT_ADMIN' || role === 'SYSTEM_ADMIN'),
            switchMap(() =>
                this.userApi.getGroups$().pipe(
                    catchError(() => {
                        this.groupOptionsError.set(true);
                        return of(<Group[]>[]);
                    })
                )
            )
        )
    );
    isRoleAssumed = computed(() => this.assumedRole() !== this.originalRole());
    isRoleLoading = computed(() => !this.originalRole());
    isRoleSelected = signal(this.isRoleAssumed());

    constructor(
        private auth: AuthService,
        private session: SessionService,
        private userApi: UsersApiService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private destroyRef: DestroyRef
    ) {
    }

    assumeRole(role: Role) {
        this.isRoleSelected.set(true);
        this.session.roleOverride$.next(role);
    }

    clearRole() {
        this.isRoleSelected.set(false);
        this.session.roleOverride$.next(this.originalRole() || '');
    }

    useOrganization(organization: Organization) {
        this.session
            .setGroupOrg({ id: organization.group_id, name: organization.group_name }, organization)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.router.navigate(['/tables']));
    }

    useGroup(group: Group) {
        this.session
            .setGroupOrg(group)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.router.navigate(['/system-admin/organizations']));
    }

    logout() {
        this.localStorageService.remove('notAccessToGroup')
        this.session.clearGroupOrg();
        this.auth.logout({ logoutParams: { returnTo: document.location.origin + '/login' } });
    }

    login() {
        this.session.clearGroupOrg();
        this.auth.loginWithRedirect();
    }
}
