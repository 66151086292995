import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(
        @Inject('API_URL') private apiUrlOptions: string[],
        private localStorageService: LocalStorageService
    ) {}

    private storedApiUrl = this.localStorageService.get('apiUrl');
    _apiUrl = this.apiUrlOptions.includes(this.storedApiUrl) ? this.storedApiUrl : this.apiUrlOptions[0];
    _organization = this.localStorageService.get('organizationId') || '';
    devMode = false;

    get apiUrl() {
        return this._apiUrl;
    }

    set apiUrl(value: string) {
        this.localStorageService.set('apiUrl', value);
        this._apiUrl = value;
    }

    get organization() {
        return this._organization;
    }

    set organization(value: string) {
        this.localStorageService.set('organizationId', value);
        this._organization = value;
    }
}
