import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private messageService: MessageService,
        private localStorageService: LocalStorageService
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (!(error.error instanceof ErrorEvent)) {
                    if (error.status === 403 && error?.error.error_code === 'missing_byod_account') {
                        this.localStorageService.set("notAccessToGroup", 'true')
                    } else {

                        const detail =
                            error.error?.message || error.error?.detail || error.error?.['error:'] || error.message;

                        const errorMessage = {
                            0: {
                                summary: 'Error',
                                detail: 'Server is not responding',
                                severity: 'error',
                            },
                            409: {
                                summary: 'Conflict',
                                detail,
                                severity: 'warn',
                            },
                            500: {
                                summary: 'Server Error',
                                detail: 'Unexpected server error occurred',
                                severity: 'error',
                            },
                        }[error.status] || {
                            summary: 'Unexpected Error',
                            detail,
                            severity: 'error',
                        };

                        this.messageService.add({ sticky: true, ...errorMessage });
                    }
                }
                return throwError(() => error);
            })
        );
    }
}
