<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <c-container>
        <c-row class="justify-content-center">
            <c-col [md]="6">
                <c-card-group>
                    <c-card class="p-4">
                        <c-card-body>
                            <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
                        </c-card-body>
                    </c-card>
                </c-card-group>
            </c-col>
        </c-row>
    </c-container>
</div>

<ng-template #loginTemplate>
    <h4 class="text-center text-transform-none">Welcome to BYOD</h4>
    @if (authUser() || notAccessToGroup()) {
        <div class="d-flex flex-column">
            @if (groupOptions()?.length || (organizationOptions()?.length && authUser())) {
                <div class="d-flex justify-content-center my-4">
                    <c-avatar shape="rounded-1" size="sm" [src]="authUser()?.picture" status="success"></c-avatar>
                    <div class="d-flex align-items-center ms-3">{{ authUser()?.email }}</div>
                    <div class="d-flex flex-column justify-content-center">
                        <span class="ms-2 badge bg-info">{{ roleLabel() }}</span>
                    </div>
                </div>
            }
            @if (notAccessToGroup()) {
                <div class="text-center">
                    Looks like you are not a part of any organizations yet. Please make sure you are invited to at least
                    one organization.
                </div>
            } @else if (isRoleLoading()) {
                <div class="text-center">
                    <c-spinner aria-hidden="true" size="sm"></c-spinner>
                </div>
            } @else {
                @if (!isRoleSelected() && originalRole() === 'ROOT_ADMIN') {
                    <h5 class="text-center mt-2">Assume role</h5>
                    <div class="d-flex mt-2">
                        <button (click)="assumeRole('SYSTEM_ADMIN')" cButton class="w-100">System Admin</button>
                        <button (click)="assumeRole('ORG_ADMIN')" cButton class="w-100 ms-1">Organization Admin</button>
                    </div>
                }

                @if (assumedRole() === 'SYSTEM_ADMIN') {
                    <div>
                        @if (groupOptions()?.length) {
                            <h5 class="text-center mb-3">Select group</h5>
                        }
                        @if (groupOptionsError()) {
                            <div class="text-center">Loading error</div>
                        } @else {
                            @if (!groupOptions()) {
                                <div class="text-center">
                                    <c-spinner aria-hidden="true" size="sm"></c-spinner>
                                </div>
                            } @else if (groupOptions()?.length) {
                                <ul>
                                    @for (group of groupOptions(); track group) {
                                        <li style="list-style: none" class="p-0 mt-2">
                                            <button (click)="useGroup(group)" pButton class="p-button-text">
                                                {{ group.name }}
                                            </button>
                                        </li>
                                    }
                                </ul>
                            } @else {
                                <div class="text-center">
                                    Looks like you are not a part of any organizations yet. Please make sure you are
                                    invited to at least one organization.
                                </div>
                            }
                        }
                    </div>
                } @else if (assumedRole() === 'ORG_ADMIN') {
                    <div>
                        @if (organizationOptions()?.length) {
                            <h5 class="text-center mb-3">Select organization</h5>
                        }
                        @if (organizationOptionsError()) {
                            <div class="text-center">Loading error</div>
                        } @else {
                            @if (!organizationOptions()) {
                                <div class="text-center">
                                    <c-spinner aria-hidden="true" size="sm"></c-spinner>
                                </div>
                            } @else if (organizationOptions()?.length) {
                                <ul>
                                    @for (organization of organizationOptions(); track organization.id) {
                                        <li style="list-style: none" class="p-0 mt-2">
                                            <button
                                                (click)="useOrganization(organization)"
                                                pButton
                                                class="p-button-text"
                                            >
                                                {{ organization.group_name }} {{ organization.name }}
                                            </button>
                                        </li>
                                    }
                                </ul>
                            } @else {
                                <div class="text-center">
                                    Looks like you are not a part of any organizations yet. Please make sure you are
                                    invited to at least one organization.
                                </div>
                            }
                        }
                    </div>
                }
            }

            @if (isRoleSelected()) {
                <button (click)="clearRole()" cButton class="px-4 mt-3" color="light">Change Role</button>
            }

            <div class="d-flex mt-5 justify-content-end">
                <button (click)="logout()" cButton class="px-4" color="secondary">Sign Out</button>
            </div>
        </div>
    } @else {
        <c-row class="justify-content-center">
            <c-col [xs]="5" class="d-flex justify-content-center mt-3">
                <button (click)="login()" cButton class="px-4">Sign In</button>
            </c-col>
        </c-row>
    }
</ng-template>
