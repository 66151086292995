import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organization, Role, User } from './user.model';
import { catchError, map, of } from 'rxjs';
import { Group } from '../api/group.model';

@Injectable({
    providedIn: 'root',
})
export class UsersApiService {
    constructor(private http: HttpClient) { }

    getCurrentUser$() {
        return this.http
            .get<User>('user-service/v1/users/current')
            .pipe(
                map((user) => ({ ...user, roleLabel: this.roleToLabel(user.role) })),
                catchError(() => of(null)));
    }

    getUsers$(id: string, isGroup: boolean = false) {
        return this.http
            .get<User[]>(`user-service/v1/${isGroup ? 'groups' : 'organizations'}/${id}/members`)
            .pipe(map((users) => users.map((user) => ({ ...user, roleLabel: this.roleToLabel(user.role) }))));
    }

    createUser$(email: string, type: 'group' | 'org', id: string) {
        return this.http.post('user-service/v1/invitations', {
            email,
            type,
            ...(type === 'org' && { org_id: id }),
            ...(type === 'group' && { group_id: id }),
        });
    }

    getOrganizations$() {
        return this.http.get<Organization[]>('user-service/v1/organizations');
    }

    getGroupOrganizations$(groupId: string) {
        return this.http.get<Organization[]>(`user-service/v1/groups/${groupId}/organizations`);
    }

    getGroups$() {
        return this.http.get<Group[]>('user-service/v1/groups');
    }

    createGroup$(name: string) {
        return this.http.post<Group>('user-service/v1/groups', { name });
    }

    removeAdminFromOrganization$(orgId: string, userId: string) {
        return this.http.delete(`user-service/v1/organizations/${orgId}/members/${userId}`);
    }

    roleToLabel(role: Role) {
        return (
            {
                ROOT_ADMIN: 'Root',
                SYSTEM_ADMIN: 'System Admin',
                ORG_ADMIN: 'Organization Admin',
                USER: 'User',
                '': '',
            }[role] ?? role
        );
    }

    createOrganization$(name: string, group_id: string) {
        return this.http.post<Organization>('user-service/v1/organizations', { name, group_id });
    }
}
