import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/pages/login/login.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { isGroupSelectedGuard } from './services/session/is-group-selected-guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivateChild: [AuthGuard, isGroupSelectedGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./views/organization-data/organization-data.module').then((m) => m.OrganizationDataModule),
            },
            {
                path: 'integrations',
                loadChildren: () =>
                    import('./views/integrations/integrations.module').then((m) => m.IntegrationsModule),
            },
            {
                path: 'map',
                loadChildren: () => import('./views/map/map.module').then((m) => m.MapModule),
            },
            {
                path: 'system-admin',
                loadChildren: () => import('./views/system-admin/system-admin.module').then((m) => m.SystemAdminModule),
            },
            {
                path: 'organization-admin',
                loadChildren: () =>
                    import('./views/organization-admin/organization-admin.module').then(
                        (m) => m.OrganizationAdminModule
                    ),
            },
            {
                path: 'settings',
                loadChildren: () => import('./views/settings/settings.module').then((m) => m.SettingsModule),
            },
            {
                path: 'etl',
                loadChildren: () => import('./views/etl/etl.module').then((m) => m.EtlModule),
            },
        ],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    { path: '**', redirectTo: 'login' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
