export const environment = {
    apiUrl: ['https://api.develop.byod.ifcmyelin.com'],
    mapbox: {
        accessToken: 'pk.eyJ1IjoidmRlcmJlbmV2IiwiYSI6ImNscmV6cXVrMDFxaDkybHNyN3dqMmY4Z3kifQ.Is0apjlBdnvGKuYJ2LkfYA',
    },
    auth: {
        domain: 'farmadvisor.us.auth0.com',
        clientId: 'j51t2do2e99ychIChlKKGldy69B1jBAH',
        authorizationParams: {
            redirect_uri: `${window.location.origin}/login`,
            audience: 'https://api.farmadvisor-dev.com/',
        },
    },
};
