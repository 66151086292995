import { RoleINavData } from './role-nav.model';

const titleCase = (str: string) => str.replace(/\b\S/g, (t) => t.toUpperCase());

export const navItems: RoleINavData[] = [
    {
        name: 'CSV',
        url: 'csv',
        iconComponent: { name: 'cil-file' },
        role: ['ORG_ADMIN'],
    },
    {
        name: 'Tables',
        url: 'tables',
        iconComponent: { name: 'cil-view-column' },
        linkProps: { routerLinkActiveOptions: { exact: true } },
        role: ['ORG_ADMIN'],
    },
    {
        name: titleCase('Location keys'),
        url: 'location-keys',
        iconComponent: { name: 'cil-link-alt' },
        linkProps: { routerLinkActiveOptions: { exact: true } },
        role: ['ORG_ADMIN'],
    },
    {
        name: 'Integrations',
        url: '/integrations',
        iconComponent: { name: 'cil-external-link' },
        role: ['ORG_ADMIN'],
    },
    {
        name: titleCase('Organization admins'),
        url: '/organization-admins',
        iconComponent: { name: 'cil-user-plus' },
        role: ['ORG_ADMIN'],
    },
    {
        name: 'Organizations',
        url: '/system-admin/organizations',
        iconComponent: { name: 'cil-industry' },
        role: ['SYSTEM_ADMIN'],
    },
    {
        name: titleCase('System admins'),
        url: '/system-admin/users',
        iconComponent: { name: 'cil-user-plus' },
        role: ['SYSTEM_ADMIN'],
    },
    {
        name: titleCase('System admins'),
        url: '/settings/system-admins',
        iconComponent: { name: 'cil-user-plus' },
        role: ['ROOT_ADMIN'],
    },
    {
        name: 'Settings',
        url: '/settings',
        linkProps: { routerLinkActiveOptions: { exact: true } },
        iconComponent: { name: 'cil-settings' },
    },
    {
        name: 'Map',
        url: '/map',
        iconComponent: { name: 'cil-map' },
        role: ['ROOT_ADMIN'],
    },
    {
        name: 'ETL',
        url: '/etl',
        iconComponent: { name: 'cil-description' },
        role: ['ROOT_ADMIN'],
        children: [
            {
                name: titleCase('Global jobs'),
                url: '/etl/global-jobs',
                iconComponent: { name: 'cil-globe-alt' },
                badge: { color: 'info', text: 'PROTO' },
            },
            {
                name: titleCase('Organization jobs'),
                url: '/etl/organization-jobs',
                iconComponent: { name: 'cil-institution' },
                badge: { color: 'info', text: 'PROTO' },
            },
        ],
    },
];
