import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from '../../services/settings/settings.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    constructor(private settingsService: SettingsService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const apiReq = request.clone({ url: `${this.settingsService.apiUrl}/${request.url}` });
        return next.handle(apiReq);
    }
}
