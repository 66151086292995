<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
    <ng-scrollbar pointerEventsMethod="scrollbar">
        <c-sidebar-nav [navItems]="navItems()" dropdownMode="close" />
    </ng-scrollbar>
    @if (!sidebar.narrow) {
        <c-sidebar-toggler cSidebarToggle="sidebar" toggle="unfoldable" />
    }
</c-sidebar>

<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
    <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar" />
    <div class="body flex-grow-1">
        <c-container breakpoint="lg" class="h-auto" [style]="fullScreen() ? 'max-width: none' : ''">
            <router-outlet />
        </c-container>
    </div>
    <app-default-footer />
</div>
