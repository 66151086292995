import { ActivatedRoute } from '@angular/router';
import { skip, take, tap } from 'rxjs/operators';
import { LocalStorageService } from './local-storage/local-storage.service';

export const appInitializer = ({ queryParams }: ActivatedRoute, { set }: LocalStorageService) => () => {
    queryParams.pipe(skip(1), take(1), tap(({ error_description }) =>
        error_description === "user_not_invited" &&
        set("notAccessToGroup", 'true')
    )).subscribe()
    return true
};
