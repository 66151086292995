import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    public set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public get(key: string, defaultValue = '') {
        return localStorage.getItem(key) || defaultValue;
    }

    public remove(key: string) {
        localStorage.removeItem(key);
    }

    public clear() {
        localStorage.clear();
    }
}
