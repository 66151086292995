import { Injectable } from '@angular/core';
import { Organization, Role } from './user.model';
import { UsersApiService } from './users-api.service';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, combineLatest, filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    roleOverride$: BehaviorSubject<Role> = new BehaviorSubject<Role>('');
    originalRole$: Observable<Role> = this.auth.isAuthenticated$.pipe(
        filter((value) => value),
        switchMap(() => this.userApi.getCurrentUser$()),
        map((user) => user?.role ?? ''),
        shareReplay()
    );
    assumedRole$: Observable<Role> = this.roleOverride$.pipe(
        switchMap((role) => (role ? this.roleOverride$ : this.originalRole$))
    );
    groupOrg$ = new BehaviorSubject<{
        group: { id: string; name: string } | null;
        organization: { id: string; name: string } | null;
    }>(this.storedGroupOrg);

    constructor(
        private auth: AuthService,
        private userApi: UsersApiService,
        private localStorageService: LocalStorageService
    ) { }

    get storedGroupOrg(): {
        group: { id: string; name: string } | null;
        organization: { id: string; name: string } | null;
        } {
        const groupId = this.localStorageService.get('groupId');
        const groupName = this.localStorageService.get('groupName');
        if (!groupId || !groupName) {
            console.log('no group stored');
            return { group: null, organization: null };
        }

        const orgId = this.localStorageService.get('organizationId');
        const orgName = this.localStorageService.get('organizationName');
        const org = orgId && orgName ? { id: orgId, name: orgName } : null;

        const assumedRole = this.localStorageService.get('assumedRole');
        if (assumedRole) {
            this.roleOverride$.next(assumedRole as Role);
        }

        return {
            group: { name: groupName, id: groupId },
            organization: org,
        };
    }

    setGroupOrg(group: { id: string; name: string }, organization: Organization | null = null) {
        this.localStorageService.set('groupId', group.id);
        this.localStorageService.set('groupName', group.name);

        if (organization) {
            this.localStorageService.set('organizationId', organization.id);
            this.localStorageService.set('organizationName', organization.name);
        }

        this.groupOrg$.next({ group, organization });

        return combineLatest([this.originalRole$, this.assumedRole$]).pipe(
            tap(([originalRole, assumedRole]) => {
                if (originalRole !== assumedRole) {
                    this.localStorageService.set('assumedRole', assumedRole);
                }
            })
        );
    }

    clearGroupOrg() {
        this.localStorageService.remove('groupId');
        this.localStorageService.remove('groupName');
        this.localStorageService.remove('organizationId');
        this.localStorageService.remove('organizationName');
        this.localStorageService.remove('assumedRole');
        this.groupOrg$.next({ group: null, organization: null });
    }
}
