import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './containers';

import {
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    SharedModule,
    SidebarModule,
    TabsModule,
    UtilitiesModule,
} from '@coreui/angular';

import { IconModule, IconSetService } from '@coreui/icons-angular';
import { APIInterceptor } from './interceptors/api/api.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ErrorInterceptor } from './interceptors/error/error.interceptor';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { LoadingComponent } from './views/partials/loading/loading.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { appInitializer } from './services/app.initializer';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from './services/local-storage/local-storage.service';

const APP_CONTAINERS = [DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent];

@NgModule({
    declarations: [AppComponent, ...APP_CONTAINERS],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AvatarModule,
        BreadcrumbModule,
        FooterModule,
        DropdownModule,
        GridModule,
        HeaderModule,
        SidebarModule,
        IconModule,
        NavModule,
        ButtonModule,
        FormModule,
        UtilitiesModule,
        ButtonGroupModule,
        ReactiveFormsModule,
        SidebarModule,
        SharedModule,
        TabsModule,
        ListGroupModule,
        ProgressModule,
        BadgeModule,
        ListGroupModule,
        CardModule,
        NgScrollbarModule,
        HttpClientModule,
        ToastModule,
        AuthModule.forRoot({
            ...environment.auth,
            httpInterceptor: {
                allowedList: [`${environment.apiUrl}/*`],
            },

        }),
        LoadingComponent,
        ConfirmDialogModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        IconSetService,
        Title,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [ActivatedRoute, LocalStorageService],
        },
        { provide: 'API_URL', useValue: environment.apiUrl },
        { provide: 'MAPBOX', useValue: environment.mapbox },
        { provide: 'AUTH', useValue: environment.auth },
        { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        HttpClientModule,
        MessageService,
        ConfirmationService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
