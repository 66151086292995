import { Component, DestroyRef, OnInit, signal } from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';
import { SessionService } from '../../services/session/session.service';
import { navItems } from './_nav';
import { RoleINavData } from './role-nav.model';

@Component({
    templateUrl: './default-layout.component.html',
    styleUrl: './default-layout.component.scss',
})
export class DefaultLayoutComponent implements OnInit {
    navItems = signal<RoleINavData[]>([]);
    fullScreen = signal(false);

    constructor(
        public auth: AuthService,
        private router: Router,
        private destroyRef: DestroyRef,
        private sessionService: SessionService
    ) {
        this.sessionService.assumedRole$.pipe(takeUntilDestroyed()).subscribe((role) => {
            this.navItems.set(structuredClone(navItems).filter((item) => this.filterNavItems(role, item)));
        });
    }

    ngOnInit() {
        this.setFullScreen();
    }

    private filterNavItems(role: string, item: RoleINavData) {
        if (role === 'ROOT_ADMIN') {
            return true;
        }
        if (item.children) {
            item.children = item.children.filter((child) => {
                if (child.role) {
                    return child.role.indexOf(role) !== -1;
                }
                return true;
            });
        }
        if (item.role) {
            return item.role.indexOf(role) !== -1;
        }
        return true;
    }

    private setFullScreen() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router),
                map((event) => event as NavigationEnd),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((event) => {
                const url = event.url.split(';')[0];
                if (url === '/map') {
                    this.fullScreen.set(true);
                }
            });
    }
}
