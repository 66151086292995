import { Component, DestroyRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { iconSubset } from './icons/icon-subset';
import { IconSetService } from '@coreui/icons-angular';
import { PrimeNGConfig } from 'primeng/api';
import { SessionService } from './services/session/session.service';
import { AuthService } from '@auth0/auth0-angular';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    isLoading = toSignal(this.auth.isLoading$);

    constructor(
        private auth: AuthService,
        private router: Router,
        private titleService: Title,
        private iconSetService: IconSetService,
        private primengConfig: PrimeNGConfig,
        private sessionService: SessionService,
        private destroyRef: DestroyRef
    ) {
        this.titleService.setTitle('BYOD');
        this.iconSetService.icons = { ...iconSubset };
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
        });

        this.sessionService.assumedRole$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
}
